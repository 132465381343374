<template>
  <div class="slider-setter" :class="{ disabled: !enabled }">
    <div class="title">
      <span>{{ data.title }}</span>
      <div class="tooltip">
        <span>{{ displayValue }} {{ data.unit }}</span>
        <TooltipElement :content="data.tooltip" alwaysShow placement="bottom">
          <img src="@/assets/icons/Info_24.svg" alt="">
        </TooltipElement>
      </div>
    </div>
    <div class="slider">
      <span class="min">{{ min }}</span>
      <input
        type="range"
        :min="data.min"
        :max="data.max"
        class="range"
        :style="rangeStyle"
        :step="data.step || 1"
        v-model="range"
      />
      <span class="max">{{ max }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderSetter',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    data: Object,
    value: {
      type: [ Number, String ],
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    min() {
      return this.data.label ? this.data.label[this.data.min] : this.data.min
    },
    max() {
      return this.data.label ? this.data.label[this.data.max] : this.data.max
    },
    range: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', Number(value))
      }
    },
    displayValue() {
      return this.data.label ? this.data.label[this.value] : this.value
    },
    rangeStyle() {
      return {
        '--range': (this.value - this.data.min) / (this.data.max - this.data.min) * 100 + '%'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.slider-setter {
  background: #4A5C7866;
  border-radius: 8px;
  position: relative;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 8px 12px;
    border-bottom: 1px solid #4A5C78;
    box-sizing: border-box;
    span {
      font-size: px2rem(20);
      line-height: 28px;
      color: #ffffff;
    }
    
    .tooltip {
      display: flex;
      align-items: center;
      img {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

  .slider {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 12px;
    box-sizing: border-box;

    span {
      min-width: 40px;
      font-size: px2rem(16);
      line-height: 24px;
      text-align: center;
      white-space: nowrap;
      color: #9D9D9D;
      &.min {
        margin-right: 7px;
      }
      &.max {
        margin-left: 7px;
      }
    }

    .divider {
      position: absolute;
      width: 3px;
      height: 6px;
      left: 25%;
      top: 18px;
      background: #282942;
    }

    .range {
      width: 100%;
      -webkit-appearance: none;
      vertical-align: middle;
      outline: none;
      border: none;
      padding: 0 2px;
      background: none;
      position: relative;
      /* z-index: 2; */
    }

    .range::-webkit-slider-runnable-track {
      background-color: #4A5C78;
      height: 6px;
      border-radius: 3px;
      background: linear-gradient(
        to right,
        #FFC600,
        #FFC600 var(--range),
        #4A5C78 var(--range),
        #4A5C78 100%
      );
    }

    .range[disabled]::-webkit-slider-runnable-track {
      opacity: 0.4;
    }

    .range::-moz-range-track {
      background-color: #d7dbdd;
      height: 6px;
      border-radius: 3px;
      border: none;
    }

    .range::-moz-range-thumb {
      border-radius: 50%;
      height: 16px;
      width: 16px;
      border: none;
      background: none;
      background-color: #ffffff;
    }

    .range:active::-moz-range-thumb {
      outline: none;
    }

    .range::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      border-radius: 50%;
      background-color: #ffffff;
      height: 16px;
      width: 16px;
      margin-top: -5px;
      cursor: pointer;
    }

    .range[disabled]::-webkit-slider-thumb {
      background-color: #ffffff;
      border: 1px solid #d7dbdd;
      cursor: default;
    }

    .range:active::-webkit-slider-thumb {
      outline: none;
    }

    .range:hover::-webkit-slider-thumb {
      background: radial-gradient(circle at 65% 30%, white 2px, rgb(235, 231, 18) 3%, rgb(127, 127, 141) 80%, rgb(18, 235, 235) 100%);
    }
  }
}
</style>